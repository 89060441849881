import React, { memo, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Header, Hero, Why, HomePortfolio, Footer, Reviews } from '@components'

import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '@elements'
import { Helmet } from 'react-helmet'
import styles from './index.module.scss'

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = props => {
  const [video, setVideo] = useState<boolean>(false)

  const imagesQuery = graphql`
    query {
      portfolio: file(relativePath: { eq: "portfolioPrev.jpg" }) {
        ...ImageFluid1440x900
      }
      about: file(relativePath: { eq: "aboutPrev.png" }) {
        ...ImageFluid1440x900
      }
      home: file(relativePath: { eq: "homePrev.jpg" }) {
        ...ImageFluid1440x900
      }
      team: file(relativePath: { eq: "teamPrev.png" }) {
        ...ImageFluid1440x900
      }
    }
  `
  const staticData = useStaticQuery(imagesQuery)

  const imageFluid = staticData.home.childImageSharp.fluid

  return (
    <section>
      <Helmet>
        <title>Palo Santo | Homepage</title>
        <meta property="og:url" content="https://palosanto.vc/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Palo Santo" />
        <meta
          property="og:description"
          content="Leading psychedelic therapeutics investment fund dedicated to increasing the global supply of clinically effective and accessible healthcare solutions"
        />
        <meta property="og:image" content="/assets/images/social.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <div className={styles.prevideoWrapper}>
        <Image data-video={video} className={styles.prevideo} fluid={imageFluid} />
      </div>
      {console.log('JUST BEFORE')}
      <video
        onLoadedData={() => {
          console.log('WHAT?')
          setVideo(true)
        }}
        data-video={video}
        className={styles.video}
        autoPlay
        muted
        playsInline
        loop
        src="../assets/videos/HOME.mp4"
        poster="../assets/images/home-poster.png"
      >
        <source src="../assets/videos/HOME.mp4" type='video/ogg; codecs="theora, vorbis"' />
      </video>
      <Header type="light" />
      <Hero type="home" />
      <Why />
      <HomePortfolio />
      <Footer />
    </section>
  )
}

export default memo(Home)
